import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import LiveFeedFilter from "../Layout/LiveFeedFilter";
import NavBar from "./Navbar";
import VerifiedIconPng from "../../Assets/verified.png";
import PostCreate from "./PostCreate";
import { ReactComponent as GifIcon } from "../../Assets/Image.svg";
import { ReactComponent as GalleryIcon } from "../../Assets/gallery.svg";
import { ReactComponent as BoostIcon } from "../../Assets/boost.svg";
import { ReactComponent as MicIcon } from "../../Assets/Mic.svg";
import { ReactComponent as SendIcon } from "../../Assets/Send.svg";
import { ReactComponent as LikeIcon } from "../../Assets/like.svg";
import { ReactComponent as RepostIcon } from "../../Assets/repost.svg";
import { ReactComponent as DislikeIcon } from "../../Assets/dislike.svg";
import { ReactComponent as LikedIcon } from "../../Assets/Liked.svg";
import { ReactComponent as RepostedIcon } from "../../Assets/reposted.svg";
import { ReactComponent as DislikedIcon } from "../../Assets/disliked.svg";

const Feed = () => {
  const { user } = useOutletContext();
  const [messages, setMessages] = useState([]);
  const [description, setDescription] = useState({});
  console.log("User===>   ", user);
  console.log("Message===>   ", messages);

  const fetchMessages = async () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");
    const lat = 31.47162;
    const long = 74.355574;

    await axios
      .get("https://api.locaided.com/v1/messages/", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
        params: {
          lat: lat,
          long: long,
        },
      })
      .then((response) => {
        setMessages(response.data.data.messages)
      })
      .catch((error) => console.error("Error fetching messages:", error));
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleLike = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .post(
          `https://api.locaided.com/v1/messages/${id}/likes`,
          {},
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((response) => {
          console.log("Liked:", response.data)
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleUnlike = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .delete(
          `https://api.locaided.com/v1/messages/${id}/unlike`,
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((response) => {
          console.log("Unliked:", response.data)
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleRepost = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .post(
          `https://api.locaided.com/v1/messages/${id}/retweet`,
          {},
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((response) => {
          console.log("Unliked:", response.data)
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleCommentChange = (id, value) => {
    setDescription((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (id) => {
    console.log(id)
    const url = `https://api.locaided.com/v1/messages/${id}/replies`;
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    if (!description.trim()) return;

    try {
      const response = await axios.post(
        url,
        {
          reply: {
            content: description,
            message_id: id,
            user_id: user.id,
          },
        },
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      );
      fetchMessages();
      console.log("Comment sent Successfully:", response.data);
      setDescription("");
    } catch (error) {
      console.error(
        "Error sending Comment:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={6} pl={0}>
        <Grid container>
          <Grid item sx={{ pb: "10px" }}>
            <PostCreate user={user} />
          </Grid>
          {messages.map((message) => (
            <Grid item key={message.id} m={1}>
              <Card
                sx={{
                  maxWidth: 600,
                  width: 600,
                  borderRadius: "12px",
                  padding: "1rem",
                  background: "#212330",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={message.user_details.profile_image.sharable}
                    alt="User"
                    sx={{ marginRight: 2 }}
                  />                    <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                      {`${message.user_details.first_name} ${message.user_details.last_name}`}
                    </span>
                    {message.user_details.verified ? (
                      <img
                        src={VerifiedIconPng}
                        alt="Verified"
                        style={{
                          width: "13px",
                          height: "13px",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      " "
                    )}
                    <span
                      style={{
                        color: "grey",
                        marginLeft: "5px",
                        fontSize: "13px",
                      }}
                    >
                      {message.user_details.username}
                    </span>
                  </Box>
                </Box>
                <Box>
                  <CardContent sx={{ padding: 0, marginTop: "0.5rem" }}>
                    <span style={{ fontSize: "0.75rem" }}>
                      {message.description}
                    </span>
                  </CardContent>
                  {message.image_url.sharable && (
                    <CardMedia
                      component="img"
                      image={message.image_url.sharable}
                      alt="Message Image"
                      sx={{
                        borderRadius: "15px",
                        marginTop: "1rem",
                        maxWidth: "100%",
                      }}
                    />
                  )}

                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {
                      message.boosted ? <Button size="small" startIcon={<BoostIcon width="20px" height="20px" />}>
                        {message.boost_count}
                      </Button> : <Button size="small" startIcon={<BoostIcon width="20px" height="20px" />}>
                        {message.boost_count}
                      </Button>
                    }
                    {
                      message.liked ? <Button size="small" startIcon={<LikedIcon width="20px" height="20px" />} >
                        {message.like_count}
                      </Button> : <Button size="small" startIcon={<LikeIcon width="20px" height="20px" />} onClick={() => handleLike(message.id)}>
                        {message.like_count}
                      </Button>
                    }
                    {
                      message.retweeted ? <Button size="small" startIcon={<RepostedIcon width="20px" height="20px" />}>
                        {message.retweeted_count}
                      </Button> : <Button size="small" startIcon={<RepostIcon width="20px" height="20px" onClick={() => handleRepost(message.id)} />}>
                        {message.retweeted_count}
                      </Button>
                    }
                    {
                      message.unliked ? <Button size="small" startIcon={<DislikedIcon width="20px" height="20px" />}>
                        {message.unlike_count}
                      </Button> : <Button size="small" startIcon={<DislikeIcon width="20px" height="20px" onClick={() => handleUnlike(message.id)} />}>
                        {message.unlike_count}
                      </Button>
                    }
                  </CardActions>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2} mt={1}>
                  <Avatar alt="User" src={user.profile_image_url.sharable} />
                  <TextField
                    placeholder="Write your Comment"
                    size="small"
                    fullWidth
                    value={description[message.id] || ""}
                    onChange={(e) => handleCommentChange(message.id, e.target.value)}
                    multiline
                    minRows={1}
                    maxRows={5}
                    sx={{
                      backgroundColor: "#2A2D3D",
                      lineHeight: "1rem",
                      color: "#D7D9E3",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "& textarea::placeholder": {
                          color: "#D7D9E3",
                          fontSize: "0.8rem",
                          opacity: 1,
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <IconButton>
                            <GalleryIcon width="20px" height="20px" />
                          </IconButton>
                          <IconButton>
                            <GifIcon width="20px" height="20px" />
                          </IconButton>
                          <IconButton
                            sx={{ backgroundColor: "#ff2557" }}
                            onClick={() => handleSubmit(message.id)}
                          >
                            {description[message.id]?.trim() ? (
                              <SendIcon width="14px" height="14px" />
                            ) : (
                              <MicIcon width="14px" height="14px" />
                            )}
                          </IconButton>
                        </>
                      ),
                    }}
                  />
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  );
};

export default Feed;
